const jsonFile = require("@/db/data.json");

const getQuestions = () => {
  return jsonFile.questions;
};

const getQuestionsCount = () => {
  return jsonFile.questions.length;
};

const getQuestionExplication = (_id) => {
  return jsonFile.questions[_id - 1].explication;
};

const getQuestionTitle = (_id) => {
  return jsonFile.questions[_id - 1].title;
};

const getQuestionAnswers = (_id) => {
  return jsonFile.questions[_id - 1].answers;
};

const getCorrectQuestionAnswer = (_id) => {
  const question = jsonFile.questions[_id - 1];
  const answers = question.answers;
  let correct = {};
  answers.forEach((ans) => {
    if (ans.correct) {
      correct = ans;
    }
  });
  return correct;
};

const getSelectedAnswer = (_questionId, _answerId) => {
  const question = jsonFile.questions[_questionId - 1];
  const answers = question.answers;

  return answers[_answerId - 1];
};

const getSystemInfos = () => {
  return jsonFile.system;
}

export {
  getQuestions,
  getQuestionExplication,
  getQuestionsCount,
  getQuestionTitle,
  getQuestionAnswers,
  getCorrectQuestionAnswer,
  getSelectedAnswer,
  getSystemInfos
};
