<template>
    <div class="carousel">
        <Carousel @transitionstart="testVideo" :autoplay="carouselTimeout" :wrap-around="true">
            <Slide v-for="slide in slides" :key="slide.id">
                <img :src="require('../assets/img/Slides/' + slide.url)" class="img" v-if="!isVideo" width="1024" height="576" />
                <video v-else ref="video" @canplay="getVideoRef" muted :autoplay="isVideo" class="video">
                    <source :src="require('../assets/img/Slides/' + slide.url)" type="video/mp4" />
                </video>
            </Slide>
        </Carousel>
    </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
    name: 'HomeCarousel',
    components: {
        Carousel,
        Slide,
    },

    data() {
        return {
            slides: [
                { id: 1, url: '01.png' },
                { id: 2, url: '02.png' },
                { id: 3, url: '03.png' },
                { id: 4, url: '04.png' },
                { id: 5, url: '05.png' },
                { id: 6, url: '06.mp4' },
                { id: 7, url: '07.png' },
                { id: 8, url: '08.png' },
                { id: 9, url: '09.png' },
            ],
            videosId: [
                { slideId: 6, length: 66000 }
            ],
            currentSlideId: 1,
            slideCounter: null,
            defaultCarouselTimeout: 6000,
            carouselTimeout: 6000,
            isVideo: false,
            videoEl: null,
        }
    },
    mounted() {
        this.slideCounter = this.$el.getElementsByClassName("carousel__liveregion");
    },

    methods: {
        testVideo() {
            this.currentSlideId = (this.slideCounter[0].innerHTML.split(" ")[1]) - 1;

            this.videosId.forEach((video) => {
                this.isVideo = this.currentSlideId + 1 === video.slideId ? true : false;

                if (this.isVideo) {
                    this.carouselTimeout = video.length;
                }
                else { this.carouselTimeout = this.defaultCarouselTimeout; }
            })

        },
    }
}
</script>

<style lang="scss" scoped>
.video {
    width: 90%;
    aspect-ratio: 16/9;
}
.img {
    width: 90%;
    height: auto;
}
</style>