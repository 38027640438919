<template>
  <router-link :to="{ name: 'Question', params: { id: 1 } }" class="bold">
    <img src="../assets/img/jouer_button.svg" class="play-button" />  
  </router-link
  >
</template>

<script>
export default {
  name: "PlayButton",
};
</script>

<style lang="scss" scoped>
.play-button {
  width: 40vw;
}
</style>
