<template>
  <div class="body">
    <div class="ct">
      <img src="./assets/img/background_top.png" class="img-header" />
      <img src="./assets/img/background_bottom.png" class="img-footer" />
      <img src="./assets/img/logo.svg" class="img-logo" />
      <img src="./assets/img/quiz_et_concours.svg" class="img-display" />
        <router-view
          :minutes="minutes"
          :seconds="seconds"
          :playerScore="playerScore"
          @startTimer="startTimerHandler"
          @stopTimer="stopTimerHandler"
          @resetTimer="resetTimerHandler"
          @addScore="addScoreHandler"
          @resetScore="resetScoreHandler"
        ></router-view>
    </div>
    <div class="ct-mint">
      <img src="./assets/img/signature_mint.svg" alt="Signature Agence MiNT" />
    </div>
  </div>
</template>

<script>
import { getSystemInfos } from '@/services/jsonService';

export default {
  name: "App",
  data() {
    return {
      minutes: 0,
      seconds: 0,
      intervalManager: null,
      playerScore: 0,
      headerSrc: '',
      headerLogo: '',
      headerDisplay: '',
      footerSrc: ''
    };
  },
  methods: {
    startTimerHandler() {
      this.intervalManager = setInterval(() => {
        if (this.seconds === 59) {
          this.minutes += 1;
          this.seconds = 0;

          if (this.minutes === 60) {
            this.gameTimeoutHandler();
          }
        } else {
          this.seconds += 1;
        }
      }, 1000);
    },

    stopTimerHandler() {
      clearInterval(this.intervalManager);
    },

    resetTimerHandler() {
      this.minutes = 0;
      this.seconds = 0;
    },

    addScoreHandler() {
      this.playerScore += 1;
    },

    resetScoreHandler() {
      this.playerScore = 0;
    },

    gameTimeoutHandler() {
      this.stopTimerHandler();
      this.resetTimerHandler();
      this.$router.push({ name: 'home' });
    }
  },
  mounted() {
    this.headerSrc = getSystemInfos()['header-src'];
    this.headerLogo = getSystemInfos()['header-logo'];
    this.headerDisplay = getSystemInfos()['header-display'];
    this.footerSrc = getSystemInfos()['footer-src'];
  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  font-family: $font-primary;

  /* Texte insélectionnable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  font-size: 2vw;
}

.body {
  height: 100vh;
}

.ct {
  height: calc(100vh - 7%);
  position: relative;
  
  .img-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .img-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .img-logo {
    width: 30%;
    position: absolute;
    top: 3%;
    margin-left: 35%;
  }

  .img-display {
    position: absolute;
    right: 5%;
    top: 5%;
    width: 30vw;
  }
}

.ct-mint {
  height: calc(100vh - 93%);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 40%;
  }
}
</style>
