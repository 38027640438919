<template>
  <div class="content">
    <div class="home">
    <div class="home-title__container">
      <h1 class="home-title" :innerHTML="homeTitle.replace('|', '<br>')"></h1>
    </div>
    <div class="carousel-container">
      <HomeCarousel class="carousel" />
      <PlayButton class="play-button" @click="$emit('startTimer')" />
    </div>    
    <div class="sub-text">
      <p>1630 Bulle</p>
    </div>
  </div>
  </div>
</template>

<script>
import PlayButton from "@/components/PlayButton";
import HomeCarousel from "@/components/HomeCarousel";
import {getSystemInfos} from "@/services/jsonService";

export default {
  name: "HomeView",
  components: { PlayButton, HomeCarousel },
  data() {
    return {
      homeTitle: '',
    }
  },
  mounted() {
    this.homeTitle = getSystemInfos()['home-title'];
  }
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: 2rem;
  margin-bottom: -40%;
}

.carousel {
  margin-bottom: 10rem;
  z-index: 1;
}
.carousel-container {
  position: relative;
}
.play-button {
  letter-spacing: .2rem;
  position: absolute;
  right: 26%;
  bottom: -32%;
  z-index: 2;
}

.home-title__container {
  width: 100%;
  text-align: center;
  padding: 3em 0;
}

.home-title {
  width: 100%;
  font-size: 2em;
  font-weight: 300;
  line-height: 1.6em;
}

.content {
  padding-top: 30%;
  min-height: 30vh;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.sub-text {
  text-align: center;
  font-size: 2.6em;
  margin-top: 5em;
  font-weight: 700;
}
</style>
